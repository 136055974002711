import React from "react";
import InformationCard from "./InformationCard";
import { faHeartPulse, faTruckMedical, faTooth } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>¿Qué nos diferencia de otras agencias de seguros?</span>
        </h3>
        <p className="info-description" style={{ textAlign: 'justify' }}>
        Nuestro compromiso contigo va más allá de una simple póliza de seguro; buscamos acompañarte en un viaje hacia un futuro más saludable, 
        donde siempre puedas contar con la seguridad de tener un respaldo confiable en cada consulta, tratamiento o trámite. Nos esforzamos por 
        brindarte una atención cercana y personalizada, escuchando y atendiendo tus necesidades con calidez y profesionalismo.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="¿Te están cobrando mucho por tu seguro?"
          description={
            <p style={{ textAlign: 'justify' }}>
              "En Family Insurance Enterprise, creemos que el acceso a un seguro de salud de calidad no debería romper tu presupuesto. Sabemos lo frustrante que puede 
          ser pagar primas elevadas sin obtener el valor que mereces. Por eso, estamos comprometidos a ofrecerte planes de seguro de salud asequibles y completos."
          </p>
        }
          icon={faHeartPulse}
        />

        <InformationCard
          title="¿No te atiende tu agente cuando llamas?"
          description={
            <p style={{ textAlign: 'justify' }}>
              Sabemos lo frustrante que puede ser no recibir la atención que necesitas cuando más lo necesitas. 
              Tu tranquilidad y bienestar son nuestra prioridad, y estamos aquí para asegurarnos de que siempre tengas el soporte adecuado.
            </p>
          }
          icon={faHeartPulse}
        />

        <InformationCard
          title="¿Tienes problemas con tus impuestos por el seguro?"
          description={
            <p style={{ textAlign: 'justify' }}>
              "Gestionar los impuestos relacionados con tu seguro de salud puede ser una tarea complicada y estresante. 
          En Family Insurance Enterprise, entendemos tus preocupaciones y estamos aquí para ayudarte a navegar por este proceso con facilidad."
          </p>
          }
          icon={faHeartPulse}
        />
      </div>
    </div>
  );
}

export default Info;
