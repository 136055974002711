import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>Sobre nosotros</span>
        </h3>
        <p className="about-description" style={{ textAlign: 'justify' }}>
        ¡Somos Family Insurance Enterprise, tu socio de confianza para una cobertura de salud accesible y personalizada! Disfruta de cobertura médica completa, 
        asistencia en reclamaciones y beneficios exclusivos. Priorizamos tu bienestar con servicios especializados al alcance de tu mano. Únete a nosotros en este viaje 
        hacia un futuro más saludable. Tu bienestar es nuestra prioridad, con atención personalizada a cada paso.
        </p>

        <h4 className="about-text-title">Family Insurance Enterprise</h4>

      
      </div>
    </div>
  );
}

export default About;
